import Vue from 'vue';
import axios from 'axios';
import {omit, clone, get, set, isNumber, isEmpty, pick, merge} from 'lodash';
import {CALCULATOR_API} from '@/config';
import {ZoneCodes} from '@/constants/zone';
import {IgnorableFieldsWhenCopying, WarningRanges} from '@/constants/preopdata';
import {formatNumber, decimalSeparatorFormatter, parseToNumber} from '@/utilities/formatters';
import {mapObjectRecursively} from '@/utilities/object';

function tomm(value) {
    if (!isNumber(value) && isEmpty(value)) return value;
    return Number(value) / 1000;
}

function toµm(value) {
    if (!isNumber(value) && isEmpty(value)) return value;
    return Number(value) * 1000;
}

export const Status = Object.freeze({
    SAVED: 'Saved',
    CALCULATED: 'Calculated',
    TARGET_LENS_PICKED: 'Target Lens Picked',
    LOCKED: 'Locked',
});

export const LensModelCodes = Object.freeze({
    SPHERIC: 'ICL',
    TORIC: 'TICL',
});

export const EyeSides = Object.freeze({
    OD: 'OD',
    OS: 'OS',
});

export const RefractionTypes = Object.freeze({
    MANIFEST: '1',
    CYCLO: '2',
    CL: '3',
});

export const DefaultRefractionInfo = (editing = false, decimalSeparator = '.') => ({
    1: {
        preOpRefractionDataId: 0,
        refractionTypeId: 1,
        refractionName: 'Manifest',
        refractionKeyName: 'manifest',
        bvd: decimalSeparatorFormatter('12.00', decimalSeparator),
        sphere: '',
        cylinder: '',
        axis: '',
        active: !editing,
    },
    2: {
        preOpRefractionDataId: 0,
        refractionTypeId: 2,
        refractionName: 'Cycloplegic refraction',
        refractionKeyName: 'cycloplegic',
        bvd: decimalSeparatorFormatter('12.00', decimalSeparator),
        sphere: '',
        cylinder: '',
        axis: '',
        active: false,
    },
    3: {
        preOpRefractionDataId: 0,
        refractionTypeId: 3,
        refractionName: 'CL OVER-REFRACTION',
        refractionKeyName: 'cloverref',
        bvd: decimalSeparatorFormatter('12.00', decimalSeparator),
        sphere: '',
        cylinder: '',
        axis: '',
        cl: '',
        active: false,
    },
});

export const DefaultPreOpData = (eyeSide, decimalSeparator = '.') => ({
    preOpDataId: 0,
    dataSourceId: 0,
    opEye: eyeSide,
    k1: '',
    kAxis: '',
    k2: '',
    k2Axis: '',
    acd: '',
    ct: '',
    ww: '',
    monocular: false,
    lensModelCode: '',
    asphericEdof: false,
    refractionInfo: DefaultRefractionInfo(false, decimalSeparator),
    status: Status.SAVED,
});

export const DefaultPreOpDataPerEye = (payload = {}) => ({
    ...payload,
    lensCertificationSummary: {},
    preOpData: Object.values(EyeSides).reduce(
        (sides, currentSide) => ({
            ...sides,
            [currentSide]: DefaultPreOpData(currentSide, payload.decimalSeparator),
        }),
        {}
    ),
});

export const DefaultManufactureInventory = (eyeSide) => ({
    lensId: 'Txxxxxx',
    model: 'TBD',
    version: 'TBD',
    location: 'STAAR',
    delivery: '6 weeks',
    sphere: 0,
    cylinder: 0,
    axis: 0,
    expectedRef: 'TBD',
    expectedSeq: 'TBD',
});

export const defaultFormState = () => ({
    OD: {
        touched: false,
        invalid: false,
        validations: {},
    },
    OS: {
        touched: false,
        invalid: false,
        validations: {},
    },
});

//
// STATE
//
const state = () => ({
    formState: defaultFormState(),
    preopdata: DefaultPreOpDataPerEye(),
    inventoryResults: {},
    validationRanges: null,
    activeEye: EyeSides.OD,
});

//
// GETTERS
//
const getters = {
    preopdata: (state) => state.preopdata,
    preOpDataSets: (state) => Object.values(state.preopdata.preOpData),
    validationRanges: (state) => {
        if (!state.validationRanges) {
            return state.validationRanges;
        }
        return state.validationRanges.reduce((obj, item) => {
            obj[item.fieldName.toLowerCase()] = item;
            return obj;
        }, {});
    },
    activeEye: (state) => state.activeEye,
    region: (state) => state.region,
    activeRefractionType: (state) => {
        return Object.values(EyeSides).reduce((obj, eyeSide) => {
            obj[eyeSide] = Object.entries(state.preopdata.preOpData[eyeSide].refractionInfo).find(
                (item) => item[1].active
            )[0];
            return obj;
        }, {});
    },
    highCylinderEdofSphericWarning: ({preopdata}, {activeRefractionType}) => {
        return Object.values(EyeSides).filter((value) => {
            const status = get(preopdata, `preOpData[${value}].status`);
            const asphericEdof = get(preopdata, `preOpData[${value}].asphericEdof`);
            const cylinder = parseToNumber(
                get(
                    preopdata,
                    `preOpData[${value}].refractionInfo[${activeRefractionType[value]}].cylinder`
                )
            );
            const isOppositeEyeMonocular = get(
                preopdata,
                `preOpData[${value == EyeSides.OD ? EyeSides.OS : EyeSides.OD}].monocular`
            );
            return (
                !!cylinder &&
                !isOppositeEyeMonocular &&
                asphericEdof &&
                status === Status.SAVED &&
                Math.abs(Number(cylinder)) > WarningRanges.CYLINDER.MIN
            );
        });
    },
    activeRefractionTypeWarning: ({preopdata}, {activeRefractionType}) => {
        const odSide = get(preopdata, `preOpData[${EyeSides.OD}]`);
        if (odSide.monocular && activeRefractionType[EyeSides.OD] !== RefractionTypes.MANIFEST) {
            return {
                [EyeSides.OD]:
                    DefaultRefractionInfo()[activeRefractionType[EyeSides.OD]].refractionKeyName,
            };
        }
        const osSide = get(preopdata, `preOpData[${EyeSides.OS}]`);
        if (osSide.monocular && activeRefractionType[EyeSides.OS] !== RefractionTypes.MANIFEST) {
            return {
                [EyeSides.OS]:
                    DefaultRefractionInfo()[activeRefractionType[EyeSides.OS]].refractionKeyName,
            };
        }
        return Object.entries(activeRefractionType)
            .filter(([_, value]) => value !== RefractionTypes.MANIFEST)
            .reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: DefaultRefractionInfo()[value].refractionKeyName,
                }),
                {}
            );
    },
    reserveSphereRangeWarning: ({preopdata}, {activeRefractionType}) => {
        return Object.values(EyeSides).filter((value) => {
            const status = get(preopdata, `preOpData[${value}].status`);
            const sphere = parseToNumber(
                get(
                    preopdata,
                    `preOpData[${value}].refractionInfo[${activeRefractionType[value]}].sphere`
                )
            );
            const isOppositeEyeMonocular = get(
                preopdata,
                `preOpData[${value == EyeSides.OD ? EyeSides.OS : EyeSides.OD}].monocular`
            );
            return (
                !!sphere &&
                !isOppositeEyeMonocular &&
                status === Status.SAVED &&
                Number(sphere) > WarningRanges.SPHERE.MIN &&
                Number(sphere) <= WarningRanges.SPHERE.MAX &&
                activeRefractionType[value] !== RefractionTypes.CL
            );
        });
    },
    clSphereNonZeroWarning: ({preopdata}, {activeRefractionType}) => {
        return Object.values(EyeSides).filter((value) => {
            const status = get(preopdata, `preOpData[${value}].status`);
            const clSphere = parseToNumber(
                get(preopdata, `preOpData[${value}].refractionInfo[${RefractionTypes.CL}].cl`)
            );
            const isOppositeEyeMonocular = get(
                preopdata,
                `preOpData[${value == EyeSides.OD ? EyeSides.OS : EyeSides.OD}].monocular`
            );
            return (
                !!clSphere &&
                !isOppositeEyeMonocular &&
                status === Status.SAVED &&
                Number(clSphere) == 0 &&
                activeRefractionType[value] === RefractionTypes.CL
            );
        });
    },
    preopdataToSave: ({preopdata}, _, __) => {
        const payload = {
            preOpData: {},
            ...omit(preopdata, 'preOpData', 'lensCertificationSummary'),
        };

        const composePayload = function (eyeSide) {
            payload.preOpData[eyeSide] = {
                refractionInfo: {},
                ...omit(preopdata.preOpData[eyeSide], 'refractionInfo'),
            };
            const entries = Object.entries(preopdata.preOpData[eyeSide].refractionInfo);
            entries.forEach(([k, v]) => {
                payload.preOpData[eyeSide].refractionInfo[k] = clone(v);
            });
            // Returning back ct to its original mm representation
            payload.preOpData[eyeSide].ct = formatNumber(tomm(payload.preOpData[eyeSide].ct), 3);
            // Removing comma delimiter sepator in case current user have this separator
            payload.preOpData[eyeSide] = mapObjectRecursively(
                payload.preOpData[eyeSide],
                (value) => (typeof value === 'string' ? parseToNumber(value) : value),
                ['calculatorResults']
            );
        };

        const entries = Object.entries(preopdata.preOpData).filter(
            ([_, eyeSideValue]) => eyeSideValue.status === Status.SAVED
        );
        const [eyeSide] = entries.find(([_, eyeSideValue]) => eyeSideValue.monocular) ?? [];

        if (eyeSide) {
            composePayload(eyeSide);
        } else {
            entries.map(([eyeSide]) => composePayload(eyeSide));
        }

        return payload;
    },
    isOdSideInvalid({formState}) {
        return !formState.OD.touched || formState.OD.invalid;
    },
    isOsSideInvalid({formState}) {
        return !formState.OS.touched || formState.OS.invalid;
    },
    isSaveDisabled: ({preopdata, formState}, {isOdSideInvalid, isOsSideInvalid}) => {
        if (get(preopdata, `preOpData[${EyeSides.OD}].monocular`, false)) return isOdSideInvalid;
        if (get(preopdata, `preOpData[${EyeSides.OS}].monocular`, false)) return isOsSideInvalid;
        if (!preopdata.preOpDataSetId) return isOdSideInvalid || isOsSideInvalid;
        return (
            formState.OD.invalid ||
            formState.OS.invalid ||
            (!formState.OD.touched && !formState.OS.touched)
        );
    },
    isCalculateDisabled: ({preopdata, formState}) => {
        if (!preopdata.preOpDataSetId) return true;
        const odSide = get(preopdata, `preOpData[${EyeSides.OD}]`);
        if (odSide.monocular) return odSide.status !== Status.SAVED || formState.OD.touched;
        const osSide = get(preopdata, `preOpData[${EyeSides.OS}]`);
        if (osSide.monocular) return osSide.status !== Status.SAVED || formState.OS.touched;
        return (
            ![odSide.status, osSide.status].includes(Status.SAVED) ||
            formState.OD.touched ||
            formState.OS.touched
        );
    },
    isPrintDisabled: ({preopdata, formState}) => {
        if (!preopdata.preOpDataSetId) return true;
        if (get(preopdata, `preOpData[${EyeSides.OD}].monocular`, false))
            return formState.OD.touched;
        if (get(preopdata, `preOpData[${EyeSides.OS}].monocular`, false))
            return formState.OS.touched;
        return formState.OD.touched || formState.OS.touched;
    },
};

//
// MUTATIONS
//
const mutations = {
    // Set the preop data
    /* Example data
    {
        "preOpDataSetId": 56283,
        "patientId": 24013,
        "preOpData": {
            "OD": {
                "opEye": "OD",
                "k1": "40.0000",
                "kAxis": "80.0000",
                "k2": "44.5000",
                "k2Axis": "170.0000",
                "acd": "3.2000",
                "ct": "0.6000",
                "ww": "11.5000",
                "monocular": true,
                "lensModelCode": "TICL",
                "asphericEdof": false,
                "refractionInfo": {
                    "1": {
                        "refractionTypeId": 1,
                        "refractionName": "Manifest",
                        "bvd": "12.0000",
                        "sphere": "-18.0000",
                        "cylinder": "5.0000",
                        "axis": "170.0000",
                        "cl": "0.6000",
                        "active": true
                    }
                }
            }
        }
    }
    */
    setPreopdata(state, {preopdata, decimalSeparator, isCopying = false}) {
        if (preopdata.preOpData) {
            // preOpDataSetId not being set means that the preopdata hasn't been saved
            // isCopying true means this is being call from the copy action
            // which means when has value you're in editing mode
            const editing = !!preopdata.preOpDataSetId || isCopying;
            // Make sure all fields are present for both eyes
            // Build an object with all fields and default values,
            // then overwrite with incoming data
            const dataSet = merge(
                {
                    [EyeSides.OD]: DefaultPreOpData(EyeSides.OD, decimalSeparator),
                    [EyeSides.OS]: DefaultPreOpData(EyeSides.OS, decimalSeparator),
                },
                pick(state.preopdata.preOpData, Object.keys(preopdata.preOpData)),
                preopdata.preOpData
            );

            preopdata.preOpData[EyeSides.OS] &&
                (dataSet[EyeSides.OS].refractionInfo = Object.assign(
                    DefaultRefractionInfo(editing),
                    preopdata.preOpData[EyeSides.OS].refractionInfo
                ));
            preopdata.preOpData[EyeSides.OD] &&
                (dataSet[EyeSides.OD].refractionInfo = Object.assign(
                    DefaultRefractionInfo(editing),
                    preopdata.preOpData[EyeSides.OD].refractionInfo
                ));

            preopdata.preOpData = dataSet;

            //conform the data to the restrictions in number of decimal places
            for (const [, eyeData] of Object.entries(preopdata.preOpData)) {
                for (const [itemKey, itemData] of Object.entries(eyeData)) {
                    const ignoreFields = ['preOpDataId', 'dataSourceId', 'calculatorResults', 'ct'];
                    if (ignoreFields.includes(itemKey)) continue;
                    if (itemKey == 'refractionInfo') {
                        //process the refraction info
                        for (const [infoKey, infoData] of Object.entries(itemData)) {
                            //we should now have a refraction data set, so process it
                            for (const [refKey, refData] of Object.entries(infoData)) {
                                //console.log('working on ', refKey, refData);
                                if (!isNaN(refData) && typeof refData != 'boolean') {
                                    if (
                                        ['refractionTypeId', 'preOpRefractionDataId'].includes(
                                            refKey
                                        )
                                    ) {
                                        continue;
                                    } else {
                                        const decimalPlaces = refKey == 'axis' ? 0 : 2;
                                        const plusSign =
                                            ['sphere', 'cylinder', 'cl'].includes(refKey) &&
                                            refData > 0
                                                ? '+'
                                                : '';
                                        eyeData[itemKey][infoKey][refKey] =
                                            plusSign +
                                            formatNumber(refData, decimalPlaces, decimalSeparator);
                                    }
                                }
                            }
                        }
                    } else if (!isNaN(itemData) && typeof itemData != 'boolean') {
                        if (itemKey.includes('Axis')) {
                            eyeData[itemKey] = formatNumber(itemData, 0);
                        } else {
                            eyeData[itemKey] = formatNumber(itemData, 2, decimalSeparator);
                        }
                    } else {
                        //console.log(itemKey, ' is not a number');
                    }
                }
                eyeData.ct = formatNumber(toµm(eyeData.ct), 0); // CCT value is stored in mm but at UI representation we want to handle µm
            }
        }

        state.preopdata = preopdata;
    },

    resetPreopdata(state, payload) {
        state.preopdata = DefaultPreOpDataPerEye(payload);
    },

    setValidationRanges(state, validationRanges) {
        // Edge case in which we want to represent ct mm value at µm representation
        const validationRange = validationRanges.find((v) => v.fieldName === 'Ct');
        if (validationRange) {
            validationRange.max = toµm(validationRange.max);
            validationRange.min = toµm(validationRange.min);
        }
        state.validationRanges = validationRanges;
    },

    setActiveEye(state, eyeSide) {
        state.activeEye = eyeSide;
    },

    resetEye(state, {eyeSide, decimalSeparator}) {
        Vue.set(state.preopdata.preOpData, eyeSide, DefaultPreOpData(eyeSide, decimalSeparator));
    },

    setActiveRefractionType(state, {eyeSide, refractionType}) {
        Object.values(RefractionTypes).forEach((type) => {
            state.preopdata.preOpData[eyeSide].refractionInfo[type].active =
                type === refractionType;
        });
    },

    setCalculationResults(state, calculatorResultSets) {
        calculatorResultSets.forEach((calculatorResults) => {
            /* 
                Calculator API do not return the selectedLength property on each record on the target table
                we need to add it because when Orion API returns the target tables that is stored in database
                current model don't match and vuejs triggers a odd behaviour described on StaarSurgical/Orion#3226
            */
            calculatorResults.result.targetTable = calculatorResults.result.targetTable.map(
                (record) => {
                    return {...record, selectedLength: record.selectedLength ?? 0};
                }
            );
            const eyeSide = get(calculatorResults, 'opEye');
            const suggestedCylinder = get(
                state,
                `preopdata.preOpData[${eyeSide}].calculatorResults.suggestedCylinder`,
                calculatorResults.cylinder
            );
            set(state, `preopdata.preOpData[${eyeSide}].calculatorResults`, {
                ...calculatorResults,
                suggestedCylinder,
            });
            set(state, `preopdata.preOpData[${eyeSide}].status`, Status.CALCULATED);
        });
    },

    setCalculationResultsLensDescription(state, {calculationResultsSets, results}) {
        calculationResultsSets.forEach((calculationResults, index) => {
            const result = results[index];
            const description = get(result, 'value.data.results.updatedDescription');
            if (result.status === 'fulfilled' && description) {
                set(
                    state,
                    `preopdata.preOpData[${calculationResults.opEye}].calculatorResults.result.lensInformation.description`,
                    description
                );
            }
        });
    },

    setInventoryResults(state, {side, results}) {
        Vue.set(state.inventoryResults, side, results);
    },

    setPreOpDataStatus(state, {status, sides}) {
        sides.forEach((side) => set(state, `preopdata.preOpData[${side}].status`, status));
    },

    setFormState(state, {side, formState}) {
        set(state, `formState[${side}]`, formState);
    },

    setLensCertificationSummary(state, payload) {
        Vue.set(state.preopdata, 'lensCertificationSummary', payload);
    },
};

//
// ACTIONS
//
const actions = {
    resetPreopdata({commit}, payload) {
        commit('resetPreopdata', payload);
    },
    async fetchValidationRanges({commit}, {patientId}) {
        let urlString = `preop/validationranges/${patientId}`;
        const validationRanges = (await this._vm.$http.get(urlString)).data.results;

        commit('setValidationRanges', validationRanges);
    },

    async fetchPreopdata({commit}, {patientId, preOpDataSetId, decimalSeparator}) {
        let urlString = `preop/${patientId}/${preOpDataSetId}`;
        const preopdata = (await this._vm.$http.get(urlString)).data.results;

        // Map server properties to client properties
        if (preopdata.preOpData && preopdata.doctor) {
            preopdata.doctor.doctorId = preopdata.doctor.surgeonId;
            preopdata.doctor.ocosDoctorId = preopdata.doctor.ocosSurgeonId;
        }

        commit('setPreopdata', {preopdata, decimalSeparator});

        // Ensure data exists for both eyes
        Object.values(EyeSides).forEach((eyeSide) => {
            if (!(eyeSide in preopdata.preOpData)) {
                commit('resetEye', {eyeSide, decimalSeparator});
            }
        });
    },
    async copyPreopdata({commit}, {patientId, preOpDataSetId, decimalSeparator}) {
        let urlString = `preop/${patientId}/${preOpDataSetId}`;
        const {results} = (await this._vm.$http.get(urlString)).data;
        const preopdata = omit(results, IgnorableFieldsWhenCopying);
        commit('resetPreopdata', {decimalSeparator});
        commit('setPreopdata', {preopdata, decimalSeparator, isCopying: true});
    },
    async fetchAvailableLenses({commit}, {side, preOpDataId, payload}) {
        const {data} = await this._vm.$http.post(
            `v1.1/calculations/${preOpDataId}/lenses`,
            payload
        );
        commit('setInventoryResults', {
            side,
            results: data.results,
        });
        return data;
    },

    async fetchCalculation(_, preOpDataId) {
        const {data} = await this._vm.$http.get(`calculations/${preOpDataId}`);
        return data;
    },

    async unlockLenses(_, payload) {
        return await this._vm.$http.post(`preop/lenses/unlock`, payload);
    },

    async checkRefractiveCornealCylinder({getters}) {
        const {preopdataToSave, validationRanges} = getters;
        const payload = Object.values(preopdataToSave.preOpData).map((sideEye) => ({
            k1Power: sideEye.k1,
            k1Degree: sideEye.kAxis,
            k2Power: sideEye.k2,
            magCutoff: validationRanges.magcutoff.max,
            ...pick(
                Object.values(sideEye.refractionInfo).find((refraction) => refraction.active),
                ['sphere', 'bvd', 'cylinder', 'axis']
            ),
        }));

        const {data: results} = await axios.post(
            `${CALCULATOR_API.BASE_URL}/refractiveCornealCylinderWarning`,
            payload,
            {
                timeout: CALCULATOR_API.TIMEOUT,
            }
        );

        return Object.keys(preopdataToSave.preOpData).reduce(
            (acc, key, index, array) => ({
                ...acc,
                [key]: results[index].result,
                areAllFailed: array.length > 1 && acc.areAllFailed && results[index].result,
            }),
            {areAllFailed: true}
        );
    },

    async saveOrUpdatePreopData({commit, getters}, decimalSeparator) {
        const {preopdataToSave} = getters;

        const request = preopdataToSave.preOpDataSetId ? this._vm.$http.put : this._vm.$http.post;

        return new Promise((resolve, reject) => {
            request('preop/', preopdataToSave)
                .then((response) => {
                    let {success, results} = response.data;
                    if (success) {
                        results = omit(results, ['preOpData.OD.status'], ['preOpData.OS.status']);
                        const preopdata = merge({...preopdataToSave}, results);
                        commit('setPreopdata', {preopdata, decimalSeparator});
                    }
                    resolve(response);
                })
                .catch(reject);
        });
    },

    async submitPreopdataCalculation({getters}, {preOpDataId, cylinderPower} = {}) {
        const {
            preopdata: {preOpDataSetId},
            preOpDataSets,
        } = getters;

        let {
            data: {results: inputs},
        } = await this._vm.$http.get(`preop/calculatorinput/${preOpDataSetId}`);

        /**
         * If cylinder power change we need to retrigger calculation with the selected value
         */
        if (preOpDataId && cylinderPower) {
            inputs = inputs.filter((x) => x.preOpDataId === preOpDataId);
            set(inputs, `0.cylinder`, cylinderPower);
        } else {
            inputs = inputs.filter((input) =>
                preOpDataSets.some(
                    (preOp) =>
                        preOp.preOpDataId === input.preOpDataId && preOp.status === Status.SAVED
                )
            );
        }

        ///replace the above with a US Calculator check
        ///using the /uscalculator URL if we're in the US Zone
        let activeCalculatorUrl = CALCULATOR_API.BASE_URL;

        if (this.state.zone.currentZone == ZoneCodes.US) {
            activeCalculatorUrl += '/uscalculator';
        }
        const {data: results} = await axios.post(activeCalculatorUrl, inputs, {
            timeout: CALCULATOR_API.TIMEOUT,
        });

        const calculationResultsSets = results.map((current, index) => {
            return {
                ...inputs[index],
                ...current,
                // Sets the value for the cylinder based on selected one or the first target table record
                cylinder: cylinderPower || get(current, 'result.targetTable.0.cylinder', 0),
            };
        });

        return calculationResultsSets;
    },

    async saveCalculationResults({commit}, calculationResultsSets) {
        // This save calculations results obtained from calculator endpoint
        const promises = calculationResultsSets.map((calculationResults) =>
            this._vm.$http.post(
                `calculations/${calculationResults.preOpDataId}`,
                calculationResults
            )
        );

        // updated to get response from saving the pre-op data
        // contains the updated target description string
        // TODO: strip the console statements once working
        const results = await Promise.allSettled(promises);
        commit('setCalculationResults', calculationResultsSets);
        commit('setCalculationResultsLensDescription', {calculationResultsSets, results});
    },

    async saveCalculationResultsWithSelectedLens({commit}, payload) {
        // This save calculations with the selected lens picked up from target lens panel
        const {data} = await this._vm.$http.post(
            `v1.1/calculations/${payload.preOpDataId}`,
            payload
        );
        commit('setPreOpDataStatus', {status: Status.TARGET_LENS_PICKED, sides: [payload.side]});
        return data;
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
